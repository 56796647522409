import * as React from 'react'
import styled from 'styled-components'

import BackgroundSrc from './header@2x.jpg';

import { PrimaryButton } from '../Button/Button'
import Nav from '../Nav/Nav'
import Quotes from '../Quotes/Quotes'

const StyledHeader = styled.header`
  min-height: 69.6rem;
  background: url('${BackgroundSrc}');
  background-size: cover;
`;

const StyledNav = styled(Nav)`
  margin-bottom: 8.4rem;
`

const ButtonContainer = styled.div`
  text-align: center;
`

const links = [
  { url: '/projects', label: 'Projekty'},
  { url: '#about', label: 'O nas'},
  { url: '#contact', label: 'Kontakt'},
]

const Header = ({ quotes, translations }) => (
  <StyledHeader>
    <StyledNav links={links} />
    <Quotes quotes={quotes} />

    <ButtonContainer>
      <PrimaryButton
        as="a"
        href="/projects"
        title={translations['header.projects_link']}
      >
        {translations['header.projects_link']}
      </PrimaryButton>
    </ButtonContainer>
  </StyledHeader>
)

export default Header