import * as React from 'react'

import About from '../components/About/About'
import Clients from '../components/Clients/Clients'
import Header from '../components/Header/Header'
import Page from '../components/Page/Page'
import PageHeader from '../components/PageHeader/PageHeader'
import Projects from '../components/Projects/Projects'
import Team from '../components/Team/Team'
import Values from '../components/Values/Values'


const IndexPage = ({ pageContext: { currentLang, categories, projects, quotes, teamMembers, translations, sections, values, currentCategory, clients }}) => {

  return (
    <Page
      translations={translations}
      currentLang={currentLang}
    >
      <Header quotes={quotes} translations={translations} />
      <PageHeader displayOn={150} />
      <Projects
        categories={categories}
        currentCategory={currentCategory}
        projects={projects}
        translations={translations}
        section={sections['projects']}
        showProjectsLink={true}
      />
      <About section={sections['about']} />
      <Team members={teamMembers} section={sections['team']} />
      <Values section={sections['values']} values={values} />
      <Clients clients={clients} section={sections['customers']}/>
    </Page>
  )
}

export default IndexPage
