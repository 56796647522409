import * as React from 'react'
import styled from 'styled-components'

import { COLORS } from '../../constants'

import FormIconSrc from './icons/form.svg'
import FunctionIconSrc from './icons/function.svg'
import DurabilityIconSrc from './icons/durability.svg'

import Content from '../Content/Content'
import { Section, SectionTitle } from '../Section/Section'

interface Value {
  name: string
  icon: string
  description: string
}

const values: Value[] = [{
  name: 'Forma',
  icon: FormIconSrc,
  description: 'Tworzymy śmiałe, niebanalne koncepcje oraz dbamy o ich wykonalność. Wiemy jak sprawić, by realizacja nie odbiegła, jakością od ambitnego projektu, a zarazem trzymamy budżet twarda ręką.'
}, {
  name: 'Funkcja',
  icon: FunctionIconSrc,
  description: 'Wierzymy, że tylko rzetelna praca pozwala uzyskać długotrwałe efekty pomimo upływającego czasu. Traktujemy, jakość wykonania jak patynę, która sprawia, że realizacja z roku na rok tylko zyskuje na wartości.'
}, {
  name: 'Trwałość',
  icon: DurabilityIconSrc,
  description: 'Jesteśmy zaangażowani w projekt na każdym jego etapie - od pierwszego szkicu, po podpis odbioru realizacji. Planujemy, liczymy, wymyślamy, kreślimy, koordynujemy - nie zostawiając niczego przypadkowi.'
}]

const ValuesList = styled.ul`
  @media(min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
`

const ValueItem = styled.li`
  text-align: center;
  margin-bottom: 4.8rem;

  &:last-child {
    margin: 0;
  }

  @media(min-width: 1025px) {
    margin-right: 6.5rem;
  }
`

const ValueName = styled.h3`
  margin-bottom: 1.8rem;
  font-size: 2rem;
  font-weight: 300;
`

const ValueIcon = styled.div`
  display: flex;
  width: 20.6rem;
  height: 20.6rem;
  align-items: center;
  justify-content: center;
  margin: 0 auto 5rem;
  border-radius: 50%;
  background: ${COLORS.greyLight};
`

const SectionDescription = styled(Content)`
  max-width: 76.8rem;
  padding: 0 1.6rem;
  margin: 0 auto 3.2rem;
  text-align: center;
`

const valuesIcons = {
  form: FormIconSrc,
  function: FunctionIconSrc,
  durability: DurabilityIconSrc
}

const Values = ({ section, values }) => (
  <Section>
    {section.name && <SectionTitle>{section.name}</SectionTitle>}

    {section.description && <SectionDescription safeContent={section.description} />}

    <ValuesList>
      {values.map(({ name, slug, description }) => (
        <ValueItem key={name}>
          <ValueIcon>
            <img src={valuesIcons[slug]} alt={name} />
          </ValueIcon>
          <ValueName>{name}</ValueName>
          <Content safeContent={description} />
        </ValueItem>
      ))}
    </ValuesList>
  </Section>
)

export default Values