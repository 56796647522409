import * as React from 'react'
import styled from 'styled-components'

import Content from '../Content/Content'
import { Section, SectionTitle } from '../Section/Section'
import Image from '../Image/Image';

const AboutContainer = styled.div`
  @media(min-width: 1024px) {
    display: flex;
    align-items: center;
  }
`

const AboutImage = styled(Image)`
  margin-bottom: 2.4rem;

  @media(min-width: 1024px) {
    margin-right: 4.8rem;
    margin-bottom: 0;
  }
`
const getImageSources = (image) => [
  { mediaQuery: '(min-width: 1025px) and (min-resolution: 192dpi)', srcSet: image.size608x2 },
  { mediaQuery: '(min-width: 1025px)', srcSet: image.size608 },
  { mediaQuery: '(min-width: 769px) and (min-resolution: 192dpi)', srcSet: image.size991x2 },
  { mediaQuery: '(min-width: 769px)', srcSet: image.size991 },
  { mediaQuery: '(min-width: 641px) and (min-resolution: 192dpi)', srcSet: image.size736x2 },
  { mediaQuery: '(min-width: 641px)', srcSet: image.size736 },
  { mediaQuery: '(min-width: 321px) and (min-resolution: 192dpi)', srcSet: image.size608x2 },
  { mediaQuery: '(min-width: 321px)', srcSet: image.size608 },
  { mediaQuery: '(min-resolution: 192dpi)', srcSet: image.size288x2 },
]


const About = ({ section }) => (
  <Section id="about">

    {section.name && <SectionTitle>{section.name}</SectionTitle>}

    <AboutContainer>
      <AboutImage
        src={section.image.size288}
        alt=""
        sources={getImageSources(section.image)}
      />
      {section.description && <Content safeContent={section.description} />}
    </AboutContainer>
  </Section>
)

export default About