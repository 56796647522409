import * as React from 'react'
import styled from 'styled-components'

import { COLORS } from '../../constants'

import Container from '../Container/Container'

const QuotesContainer = styled(Container)`
  margin-bottom: 5.8rem;
`

const Quote = styled.blockquote`
  font-size: 5.6rem;
  font-weight: 700;
  line-height: 6.7rem;
  color: ${COLORS.white};
  text-align: center;
  text-shadow: 0 0.1rem 0.6rem rgba(0, 0, 0, 0.3);
`

const Author = styled.cite`
  display: block;
  margin-top: 1.6rem;
  font-size: 3rem;
  font-weight: 400;
  text-transform: uppercase;
`
interface Quote {
  quotation: string
  author: string
}

interface QuotesProps {
  quotes: Quote[]
}

const getRandomQuote = (quotes: Quote[]): Quote => quotes[~~(Math.random() * quotes.length)]

class Quotes extends React.Component<QuotesProps> {
  state = {
    quote: {}
  }

  componentDidMount () {
    const { quotes } = this.props
    this.setState({ quote: getRandomQuote(quotes) })
  }

  render () {
    const { quote } = this.state;

    return (
      <QuotesContainer>
        <Quote>
          <div dangerouslySetInnerHTML={{__html: quote.quotation}} />
          <Author>{quote.author}</Author>
        </Quote>
      </QuotesContainer>
    )
  }
}

export default Quotes