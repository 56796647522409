import * as React from 'react'
import styled from 'styled-components'

import { COLORS } from '../../constants'

import {AltSection, SectionTitle } from '../Section/Section'

const ClinetsList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`

const ClientsItem = styled.li`
  width: 16.66%;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 2rem;
  margin-bottom: 3rem;
  text-align: center;
`

const Clients = ({ clients, section}) => (
  <AltSection>
    {section.name && <SectionTitle>{section.name}</SectionTitle>}

    <ClinetsList>
      {clients.map(({ name }) => (
        <ClientsItem key={name}>{name}</ClientsItem>
      ))}
    </ClinetsList>
  </AltSection>
)

export default Clients;