import * as React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { COLORS } from '../../constants'

import Image from '../Image/Image'
import { AltSection, SectionTitle } from '../Section/Section'

const TeamSection = styled(AltSection)`
  padding-bottom: 0.8rem;
`

const MembersList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;

  @media(min-width: 1025px) {
    margin: 0 -2rem;
  }
`

const Member = styled.li`
  width: 100%;
  margin: 0 1rem 4rem;
  overflow: hidden;
  text-align: center;

  @media(min-width: 641px) {
    width: calc(50% - 2rem);
  }

  @media(min-width: 1024px) {
    width: calc((100% / 3) - 4rem);
    margin: 0 2rem 4rem;
    text-align: left;
  }
`

const MemberImage = styled(Image)`
  margin-bottom: 2.8rem;
  border-radius: 0.6rem;
  overflow: hidden;
`

const Name = styled.h3`
  margin-bottom: 0.8rem;
  font-size: 2rem;
  font-weight: 300;
`

const Position = styled.p`
  font-size: 1.2rem;
  color: ${COLORS.greyDark};
  text-transform: uppercase;
`
const getImageSources = (image) => [
  { mediaQuery: '(min-width: 1025px) and (min-resolution: 192dpi)', srcSet: image.size340x2 },
  { mediaQuery: '(min-width: 1025px)', srcSet: image.size340 },
  { mediaQuery: '(min-width: 769px) and (min-resolution: 192dpi)', srcSet: image.size486x2 },
  { mediaQuery: '(min-width: 769px)', srcSet: image.size486 },
  { mediaQuery: '(min-width: 768px) and (min-resolution: 192dpi)', srcSet: image.size358x2 },
  { mediaQuery: '(min-width: 768px)', srcSet: image.size358 },
  { mediaQuery: '(min-width: 641px) and (min-resolution: 192dpi)', srcSet: image.size295x2 },
  { mediaQuery: '(min-width: 641px)', srcSet: image.size295 },
  { mediaQuery: '(min-width: 321px) and (min-resolution: 192dpi)', srcSet: image.size343x2 },
  { mediaQuery: '(min-width: 321px)', srcSet: image.size343 },
  { mediaQuery: '(min-resolution: 192dpi)', srcSet: image.size288x2 },
]

const Team = ({ members, section }) => (
  <TeamSection>
    {section.name && <SectionTitle>{section.name}</SectionTitle>}

    {section.description && <SectionDescription safeContent={section.description} />}

    <MembersList>

      {members.map((member) => (
        <Member key={member.id}>
          {member.image &&
            <MemberImage
              sources={getImageSources(member.image)}
              src={member.image.size288}
              alt={member.name}
            />
          }
          <Name>{member.name}</Name>
          <Position>{member.position}</Position>
        </Member>
      ))}

    </MembersList>
  </TeamSection>
)

export default Team